import ErrorSharp from '@mui/icons-material/ErrorSharp';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { useStyles } from './styles';

type Props = {
  errorMessage: string;
};

const ErrorPage: FC<Props> = ({ errorMessage }) => {
  const classes = useStyles();

  return (
    <Box className={classes.error}>
      <ErrorSharp />
      <Typography variant="h5" color="error">
        Portal Error
      </Typography>
      <Typography color="textSecondary">{errorMessage}</Typography>
    </Box>
  );
};

export default ErrorPage;
