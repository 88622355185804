import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from '../../../../styles/global/inputStyles';
import { isEmpty } from '../../../../util/commons';
import { inputPropsDefault, useStylesInputLabel } from '../styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type SelectComponentProps = {
  props?: any;
  id?: any;
  value?: any;
  disableCloseOnSelect?: any;
  noWrap?: any;
  disableClearable?: any;
  limitTags?: any;
  selectAll?: any;
  placeholder?: any;
  type?: any;
  trigger?: any;
  error?: any;
  onChange?: any;
  label?: any;
  data?: any;
  required?: any;
  fullWidth?: any;
  disabled?: any;
  multiple?: any;
};

export const allOption = {
  label: 'Seleccionar todos',
  value: 'select-all',
};

const SelectComponent: FC<SelectComponentProps> = ({
  id,
  label,
  value,
  onChange,
  error,
  data,
  required,
  fullWidth,
  disabled,
  multiple,
  disableCloseOnSelect,
  disableClearable,
  limitTags,
  placeholder,
  type,
}) => {
  const [currentData, setCurrentData] = useState<any>([]);
  const classes = useStyles();
  const classesInput = useStylesInputLabel();

  const getSortedData = () => {
    return data.sort((x: any, y: any) =>
      x.label.localeCompare(y.label, 'es', { sensitivity: 'base', numeric: true })
    );
  };

  const allOptionsFunction = () => {
    const sortedData = getSortedData().filter((option: any) => option.value != allOption.value);

    if (multiple) {
      return [allOption, ...sortedData];
    }
    return [...sortedData];
  };

  const handleAllOptionSelect = (selectedOptions: any) => {
    if (isAllOptionSelected(value) && !isAllOptionSelected(selectedOptions)) {
      return [];
    }

    if (!isAllOptionSelected(value) && isAllOptionSelected(selectedOptions)) {
      return allOptionsFunction();
    }

    if (isAllOptionSelected(value) && isAllOptionSelected(selectedOptions)) {
      return selectedOptions.filter((option: any) => option.value != allOption.value);
    }

    return selectedOptions;
  };

  const handleSingleOptionSelect = (selectedOptions: any) => {
    return selectedOptions.filter((option: any) => {
      let elementsDuplicates = null;
      const currentOption = currentData.find((element: any) => element.value == option.value);

      if (currentOption) {
        elementsDuplicates = selectedOptions.filter((currentElementDuplicate: any) => {
          return currentElementDuplicate.value == currentOption.value;
        });
      }

      return isEmpty(elementsDuplicates) || elementsDuplicates.length <= 1;
    });
  };

  const isAllOptionSelected = (options: any) => {
    return options?.filter((option: any) => option.value == allOption.value).length == 1;
  };

  const handleChangeOption = (selectedOptions: any) => {
    if (isAllOptionSelected(selectedOptions) || isAllOptionSelected(value)) {
      return handleAllOptionSelect(selectedOptions);
    }

    return handleSingleOptionSelect(selectedOptions);
  };

  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      limitTags={limitTags}
      options={data.length > 0 ? allOptionsFunction() : []}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={disableClearable}
      disabled={disabled}
      getOptionLabel={(option: any) => (option.label ? option.label : '')}
      value={multiple ? currentData : value}
      onChange={(_, newValue) => {
        if (multiple) {
          newValue = handleChangeOption(newValue);
          onChange(newValue);
          setCurrentData(newValue);
        } else {
          onChange(newValue);
        }
      }}
      renderOption={(props: any, option: any) => {
        if (multiple) {
          const currentOption = currentData.find((element: any) => element.value == option.value);
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={!isEmpty(currentOption)}
                className={classesInput.checked}
              />

              {option.label}
            </li>
          );
        } else {
          return <li {...props}>{option.label}</li>;
        }
      }}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          disabled={disabled}
          error={!!error}
          helperText={error ? error.message : null}
          required={required}
          fullWidth={fullWidth}
          value={value}
          type={type}
          InputLabelProps={{
            ...inputPropsDefault(classesInput),
          }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            className: classes.inputWithoutHover,
          }}
        />
      )}
    />
  );
};

SelectComponent.defaultProps = {
  id: uuidv4(),
  value: [],
  disableCloseOnSelect: true,
  noWrap: false,
  disableClearable: false,
  limitTags: -1,
  selectAll: false,
  placeholder: 'Seleccione',
  type: 'text',
};

export default SelectComponent;
