import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '25px 32px !important',
    background: '#FAFAFA !important',
    [theme.breakpoints.down('sm')]: {
      padding: '25px 16px !important',
    },
  },
}));
