import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: '#000',
    letterSpacing: '0.15px !important',
    lineHeight: '143% !important',
  },
  containerTitle: {
    display: 'grid',
    gap: '20px',
    width: '380px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
