import axios from 'axios';

import { CreateCasePayload } from '../pages/Authenticated/AssitanceRequest/Steps/DescriptionView/Buttons';
import { getTokenAuthentication } from '../util/commons';

export default axios.create({
  baseURL: process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL,
  headers: { 'Content-type': 'application/json' },
});

export const createCasePost = async (payload: CreateCasePayload, serviceId: any) => {
  const token = getTokenAuthentication(payload.businessUnitUUID);
  const headers = { headers: { token } };

  const endpoint = `${process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL}/front-customer/service/create-case?serviceId=${serviceId}`;
  return axios
    .post(endpoint, payload, headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const validateAndCreateService = async (
  businessUnitUUID: string | undefined,
  payload: any
) => {
  const token = getTokenAuthentication(businessUnitUUID);
  const headers = { headers: { businessUnitUUID, token: token } };

  const endpoint = `${process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL}/front-customer/booking/validate-and-create`;
  return axios
    .post(endpoint, payload, headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error('Error en la solicitud Axios:', err);
      throw err;
    });
};
