import { getFormItems } from '../../../../../components/DynamicForm/DynamicFormItemHelper';
import { allOption } from '../../../../../components/DynamicForm/Fields/CustomSelect/Select';

interface QuestionAnswerItem {
  question: string;
  questionLabel: string;
  answer: string;
}

interface QuestionAnswerResponse {
  question: string;
  answer: string;
}

const parseAnswer = (answer: any): string => {
  if (!answer) {
    return '';
  }

  if (typeof answer === 'object' && 'value' in answer) {
    return answer.value;
  }

  return answer;
};

const parseAnswers = (answers: any): string => {
  if (Array.isArray(answers)) {
    return answers
      .map((answer) => parseAnswer(answer))
      .filter((answer) => answer != allOption.value)
      .join(', ');
  }

  return parseAnswer(answers);
};

const getQuestionLabel = (dataObject: any): string => {
  if (!dataObject || !('items' in dataObject)) {
    return '';
  }

  return getFormItems(dataObject.items, 'label') || '';
};

const isValidQuestionItem = (fieldName: string, dataObject: any): boolean => {
  return 'name' in dataObject && fieldName === dataObject.name;
};

const getQuestionItem = (fieldName: string, dataMap: any): any => {
  if (!fieldName || !dataMap) {
    return null;
  }

  if (Array.isArray(dataMap)) {
    return dataMap.find((dataObject) => isValidQuestionItem(fieldName, dataObject));
  }

  if (isValidQuestionItem(fieldName, dataMap)) {
    return dataMap;
  }

  return null;
};

const parseQuestion = (
  mappedQuestions: QuestionAnswerItem[],
  questionAnswers: any,
  dataMap: any
): void => {
  if (!questionAnswers || !(typeof questionAnswers === 'object')) {
    return;
  }
  for (const [question, answers] of Object.entries(questionAnswers)) {
    const questionLabel = getQuestionLabel(getQuestionItem(question, dataMap));

    const answer = parseAnswers(answers);

    mappedQuestions.push({
      question: question,
      questionLabel: questionLabel,
      answer: answer,
    });
  }
};

const parseQuestions = (questions: any, dataMap: any): QuestionAnswerItem[] => {
  const mappedQuestions: QuestionAnswerItem[] = [];

  if (Array.isArray(questions)) {
    for (const questionAnswers of questions) {
      parseQuestion(mappedQuestions, questionAnswers, dataMap);
    }
  } else {
    parseQuestion(mappedQuestions, questions, dataMap);
  }

  return mappedQuestions;
};

export const getServiceFormQuestionsAnswers = (dynamicForm: any): QuestionAnswerResponse[] => {
  const questionsAnswers: QuestionAnswerItem[] = parseQuestions(
    (dynamicForm as any).data,
    (dynamicForm as any).apiResponse
  );

  return questionsAnswers.map((questionAnswer) => ({
    question: questionAnswer.questionLabel,
    answer: questionAnswer.answer,
  }));
};
