import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  error: {
    width: 'fit-content',
    textAlign: 'center',
    margin: '30vh auto 0',
    transform: 'translateY(-50%)',
    '& svg': {
      fontSize: '6rem',
      color: 'red',
    },
  },
}));
