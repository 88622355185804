import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '0px 0px 20px',
    marginBottom: '10px !important',
    display: 'grid',
    gap: '24px',
  },
  buttonCancel: {
    width: '200px',
    height: '36px',
    borderRadius: '15px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    fontSize: '14px !important',
    color: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
      width: '100% !important',
    },
  },

  buttonFollow: {
    height: '36px',
    borderRadius: '15px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    fontSize: '14px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `white !important`,
    width: '322px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
      width: '100% !important',
    },
  },
  buttonSubmit: {
    width: '200px',
    height: '36px',
    borderRadius: '15px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    fontSize: '14px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `white !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
      width: '100%',
    },
  },
  serviceTitle: {
    fontWeight: '500 !important',
    fontSize: '14px !important',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    gap: '20px',
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: '20px',
      flexDirection: 'column',
    },
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: theme.zIndex.drawer + 1,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: '20px',
    },
  },
  mobileContent: {
    width: '100%',
  },
  buttonCancelMB: {
    width: '200px',
    height: '36px',
    borderRadius: '15px !important',
    fontSize: '14px !important',
    color: `${theme.palette.primary.main} !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
      width: '100% !important',
    },
  },
  requestImage: {
    width: '275px',
  },
  requestTitle: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: '500',
    color: `${theme.palette.primary.main} !important`,
  },
  pausedRequestSubtitle: {
    width: 455,
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.75)',
    [theme.breakpoints.down('sm')]: {
      width: 301,
    },
  },
  pausedRequestContactPhone: {
    color: `${theme.palette.primary.main} !important`,
  },
  pausedRequestButton: {
    height: '42px',
    borderRadius: '15px !important',
    border: `1px solid ${theme.palette.primary.main} !important`,
    fontSize: '14px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `white !important`,
    width: '200px !important',
    [theme.breakpoints.down('sm')]: {
      width: '177px',
      height: '30px',
      alignSelf: 'center',
      borderRadius: '20px !important',
    },
  },
}));
