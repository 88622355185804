import { useContext, useEffect, useState } from 'react';

import { getTenantConfig, getTenantConfigPrivate } from '../api/ubigeo';
import BusinessUnitParamsContext from '../contexts/BusinessUnitParamsContext/businessUnitParamsContext';

/**
 * Obtiene la key de google maps y la retorna
 * @returns googleApiKey: String, key para usar en el plataforma con librerias de google maps
 */
const useGoogleApiKey = () => {
  const [googleApiKey, setGoogleApiKey] = useState(null);
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);

  useEffect(() => {
    if (businessUnitUUID) {
      getTenantConfig(businessUnitUUID).then((data) => {
        setGoogleApiKey(data.apiKey);
      });
    }
  }, [businessUnitUUID]);

  return googleApiKey;
};

/**
 * Obtiene la key de google maps y la retorna (Privado para usuario no autenticado / Portal público)
 * @returns googleApiKey: String, key para usar en el plataforma con librerias de google maps
 */
export const useGoogleApiKeyPrivate = () => {
  const [googleApiKey, setGoogleApiKey] = useState(null);
  const { tenantUUID } = useContext(BusinessUnitParamsContext);

  useEffect(() => {
    if (tenantUUID) {
      getTenantConfigPrivate(tenantUUID).then((data) => {
        setGoogleApiKey(data.apiKey);
      });
    }
  }, [tenantUUID]);

  return googleApiKey;
};

const addGoogleMap = (key: string, callback: any) => {
  const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
  const newScript = document.createElement('script');
  newScript.src = scriptUrl;
  newScript.id = 'googleMaps';
  document.head.appendChild(newScript);

  newScript.onload = () => {
    if (callback) callback();
  };
};

export const addGoogleMapToDom = (businessUnit: string) => {
  if (businessUnit) {
    getTenantConfig(businessUnit).then((data) => {
      addGoogleMap(data.apiKey, undefined);
    });
  }
};

export const addGoogleMapToDomPrivate = (apiKey: string, callback: any) => {
  addGoogleMap(apiKey, callback);
};

export default useGoogleApiKey;
