import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  error: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    height: '65vh',
    '& svg': {
      fontSize: '6rem',
      color: 'red',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
      alignItems: 'center',
    },
  },
  buttonSubmit: {
    width: '200px !important',
    height: '36px !important',
    borderRadius: '15px !important',
    fontSize: '14px !important',
    color: `white !important`,
    [theme.breakpoints.down('sm')]: {
      borderRadius: '20px !important',
      height: '30px !important',
      width: '177px !important',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  imageContainer: {
    display: 'flex',
    marginTop: '55px',
    marginRight: '135px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginTop: 30,
      marginRight: 0,
      marginBottom: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
    },
  },
  imageError: {
    width: '548px',
    height: '496px',
    [theme.breakpoints.down('md')]: {
      width: '274.88px',
      height: '287px',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      order: 2,
      textAlign: 'center',
    },
  },
  title: {
    color: `${theme.palette.primary.main} !important`,
    fontSize: '22px !important',
    fontWeight: '500 !important',
  },
  subtitle: {
    fontSize: '13px !important',
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  number: {
    color: `${theme.palette.primary.main} !important`,
  },
}));
