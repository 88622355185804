import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: '500 !important',
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: 'rgba(0, 0, 0, 0.75)',
  },
  container: {
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
}));
