import { FC } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { getEmailLowerCase } from '../../../../api/api';
import Container from '../../../../components/Containers/Container';
import ContainerPage from '../../../../components/LayoutAuth/ContainerPage';
import { GOOGLE_CAPTCHA_API_KEY } from '../../../../constants/constants';
import CreateAccountForm from './CreateAccountForm';

type Props = {
  emailToCreate: string;
  tokenToCreateAccount: string | string[] | null;
};

const CreateAccount: FC<Props> = ({ emailToCreate, tokenToCreateAccount }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA_API_KEY}>
      <Container>
        <ContainerPage>
          <CreateAccountForm
            emailToCreate={getEmailLowerCase(emailToCreate)}
            tokenToCreateAccount={tokenToCreateAccount}
          />
        </ContainerPage>
      </Container>
    </GoogleReCaptchaProvider>
  );
};

export default CreateAccount;
