export const CLEAR_REQUEST = 'CLEAR_REQUEST';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_CURRENT_SERVICE = 'SET_CURRENT_SERVICE';
export const SET_CURRENT_SERVICE_NAME = 'SET_CURRENT_SERVICE_NAME';
export const SET_CURRENT_CARD = 'SET_CURRENT_CARD';
export const SET_CURRENT_MATERIAL = 'SET_CURRENT_MATERIAL';
export const SET_CURRENT_PLAN = 'SET_CURRENT_PLAN';
export const SET_DESCRIPTION_PAYLOAD = 'SET_DESCRIPTION_PAYLOAD';
export const SET_HAVE_SERVICE_DESTINATION = 'SET_HAVE_SERVICE_DESTINATION';
export const SET_CASE_NUMBER = 'SET_CASE_NUMBER';
export const SET_COST_CONCEPTS = 'SET_COST_CONCEPTS';
export const SET_DYNAMIC_FORM = 'SET_DYNAMIC_FORM';
