import { FC, useContext } from 'react';

import BusinessUnitParamsContext from '../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { ServiceData } from '../../../../../models/ServiceDataModel';
import { isAuthenticate } from '../../../../../util/commons';
import { ServiceInformation } from '../OnTheWayServiceStep/ServiceInformation/ServiceInformation';
import { ScheduledServiceStepAuth } from './ScheduledServiceStepAuth/ScheduledServiceStepAuth';
import { useStyles } from './styles';

type Props = {
  serviceData: ServiceData | undefined;
  cardTitle?: string | undefined;
};

export const ScheduledServiceStep: FC<Props> = ({ serviceData, cardTitle }) => {
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);
  const isAuthenticateUser: any = isAuthenticate(businessUnitUUID);
  const classes = useStyles();

  return (
    <>
      {isAuthenticateUser ? (
        <ScheduledServiceStepAuth serviceData={serviceData} cardTitle={cardTitle} />
      ) : (
        <div className={classes.container}>
          <ServiceInformation serviceData={serviceData} />
        </div>
      )}
    </>
  );
};
