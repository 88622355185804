import { Button, Grid } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { createCasePost } from '../../../../../../api/caseCore';
import Spinner from '../../../../../../components/Spinner';
import {
  ESTATE_MATERIAL,
  NAME_APP,
  PET_MATERIAL,
  VEHICLE_MATERIAL,
} from '../../../../../../constants/constants';
import BusinessUnitParamsContext from '../../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import {
  setCaseNumber,
  setDescriptionPayload,
} from '../../../../../../redux/actions/requestActions';
import { getRequestSelector } from '../../../../../../redux/selectors';
import { useStyles } from '../../../../../../styles/global/stylesGlobal';
import { isEmpty } from '../../../../../../util/commons';

interface ButtonsProps {
  handleNext: any;
  handleBack: any;
  handleSubmit: any;
  setErrorDirection: any;
  setErrorDirectionFinal: any;
  watch: any;
  setCallback: any;
  validData: any;
}

export interface CreateCasePayload {
  businessUnitUUID: string;
  createdFrom: string;
  date: string;
  materialId: number;
  materialTypeId: number;
  time: string;
  email: string;
  mobilePhone: string;
}

const Buttons: React.FC<ButtonsProps> = ({
  handleNext,
  handleBack,
  handleSubmit,
  setErrorDirection,
  setErrorDirectionFinal,
  watch,
  setCallback,
  validData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { haveServiceDestination, caseNumber } = useSelector(getRequestSelector);
  const watchAllFields = watch();

  const { materialSelected, serviceId, selectedCard } = useSelector(getRequestSelector);
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const latestDataRef = useRef(null);

  useEffect(() => {
    if (validData) {
      latestDataRef.current && handleNextPage(latestDataRef.current);
    }
  }, [validData]);

  const submit = (data: any) => {
    setCallback(true);
    setTimeout(() => {
      setCallback(false);
    }, 1000);

    latestDataRef.current = data;
  };

  const getMaterialTypeId = (selectedType: string): number => {
    if (VEHICLE_MATERIAL === selectedType) {
      return 1;
    }

    if (ESTATE_MATERIAL === selectedType) {
      return 2;
    }

    if (PET_MATERIAL === selectedType) {
      return 4;
    }

    return 3;
  };

  const handleNextPage = (data: any) => {
    if (
      haveServiceDestination &&
      isEmpty(data.directionOrigen) &&
      isEmpty(data.directionDestination)
    ) {
      setErrorDirection(true);
      setErrorDirectionFinal(true);
    }

    if (isEmpty(data.directionOrigen)) {
      setErrorDirection(true);
    } else {
      if (haveServiceDestination && isEmpty(data.directionDestination)) {
        setErrorDirectionFinal(true);
      } else {
        dispatch(setDescriptionPayload(data));

        if (!isEmpty(caseNumber)) {
          handleNext();
          return;
        }

        const payload: CreateCasePayload = {
          businessUnitUUID: businessUnitUUID as string,
          createdFrom: NAME_APP,
          date: watchAllFields.date,
          materialId: materialSelected.id,
          materialTypeId: getMaterialTypeId(selectedCard as string),
          time: watchAllFields.time,
          email: watchAllFields.email,
          mobilePhone: watchAllFields.phoneNumber,
        };

        const createCase = async () => {
          try {
            setIsLoading(true);
            const caseCreated = await createCasePost(payload, serviceId);
            dispatch(setCaseNumber(caseCreated.caseNumber));
            setIsLoading(false);
            handleNext();
          } catch (error) {
            history.push('/error-page');
          }
        };

        createCase();
      }
    }
  };

  const onError = () => {
    if (isEmpty(watchAllFields.directionOrigen) && isEmpty(watchAllFields.directionDestination)) {
      setErrorDirection(true);
      setErrorDirectionFinal(true);
    }

    if (isEmpty(watchAllFields.directionOrigen)) {
      setErrorDirection(true);
    } else {
      if (isEmpty(watchAllFields.directionDestination)) {
        setErrorDirectionFinal(true);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className={classes.overlay}>
          <Spinner />
        </div>
      )}
      <Grid container justifyContent="flex-end" gap={2} marginTop={5}>
        <Grid item xs={12} sm={3} md={1}>
          <Button
            onClick={handleBack}
            style={{ textTransform: 'none' }}
            classes={{ root: classes.buttonCancel }}
            fullWidth
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={12} sm={3} md={1}>
          <Button
            onClick={handleSubmit(submit, onError)}
            style={{ textTransform: 'none' }}
            classes={{ root: classes.buttonSubmit }}
            fullWidth
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Buttons;
