import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { getTrackingByBooking } from '../../../api/serviceTracking';
import CircularProgressCustom from '../../../components/CircularProgressCustom/CircularProgressCustom';
import ContainerAuthPage from '../../../components/Containers/ContainerAuthPage/ContainerAuthPage';
import CustomBreadcumbs from '../../../components/CustomBreadcumbs';
import ServicesErrorPage from '../../../components/ServicesErrorPage';
import { BOOKINGS } from '../../../constants/routes';
import { SERVICE_TRACKING } from '../../../constants/views';
import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setCurrentViewLink, setCurrentViewName } from '../../../redux/actions/appActions';

const ValidateBooking = () => {
  const [showError, serShowError] = useState(false);
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    const init = async () => {
      try {
        await getTrackingByBooking(businessUnitUUID, id).then((res) => {
          if (res.link) {
            window.location.replace(res.link);
          }
        });
      } catch (error) {
        serShowError(true);
        console.error(error);
      }
    };
    init();
  }, []);

  useEffect(() => {
    dispatch(setCurrentViewName({ currentViewName: SERVICE_TRACKING }));
    dispatch(setCurrentViewLink({ currentViewLink: BOOKINGS }));
  }, []);

  return (
    <ContainerAuthPage>
      <CustomBreadcumbs isServiceTracking />
      {showError ? <ServicesErrorPage /> : <CircularProgressCustom />}
    </ContainerAuthPage>
  );
};

export default ValidateBooking;
