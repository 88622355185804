/* eslint-disable no-useless-catch */
import axios from 'axios';

import { getTokenAuthentication } from '../util/commons';

const ECOMMERCE_BASE_URL = `${process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL}/front-customer`;

export const getTenantConfig = async (businessUnitUUID: string) => {
  const token = getTokenAuthentication(businessUnitUUID);
  const path = '/location/configuration';
  const fullUrl = `${ECOMMERCE_BASE_URL}${path}`;
  try {
    const { data } = await axios.get(fullUrl, { headers: { token } });
    return data;
  } catch (err) {
    throw err;
  }
};

export const getTenantConfigPrivate = async (tenantUUID: string) => {
  const path = '/location/private/configuration';
  const fullUrl = `${ECOMMERCE_BASE_URL}${path}`;
  try {
    const { data } = await axios.get(fullUrl, { headers: { tenantUUID } });
    return data;
  } catch (err) {
    throw err;
  }
};
