import { Grid } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { getFieldsByForm, getForms } from './api';
import CardUIT from './CardUIT';
import { getFormItems, getFormValidations, getValidations } from './DynamicFormItemHelper';
import CustomSelect from './Fields/CustomSelect';
import CustomTextArea from './Fields/CustomTextArea';
import CustomTextField from './Fields/CustomTextField';
import { useStyles } from './styles';

type DynamicFormProps = {
  clearOnSubmit?: any;
  formId?: any;
  options?: any;
  onSubmit?: any;
  callback?: any;
  setVersion?: any;
};

const DynamicForm: FC<DynamicFormProps> = ({
  onSubmit,
  formId,
  setVersion,
  options,
  callback,
  clearOnSubmit,
}) => {
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);
  const { control, setValue, trigger, watch, getValues } = useForm();

  const [apiResponse, setApiResponse] = useState<any>([]);
  const [formData, setFormData] = useState<any>([]);
  const classes = useStyles();

  const privateCalls = () => {
    getFieldsByForm(businessUnitUUID, formId).then((response) => {
      setApiResponse(response);
    });
    getForms(businessUnitUUID, formId).then((response) => {
      setFormData(response);
      setVersion(response.currentVersion);
    });
  };

  useEffect(() => {
    if (options.headers?.businessUnit) {
      privateCalls();
    }
  }, []);

  useEffect(() => {
    if (callback) {
      trigger().then((e) => {
        if (e) {
          onSubmit({
            error: false,
            data: watch(),
            apiResponse: apiResponse,
            message: null,
          });

          if (clearOnSubmit) clearFormData();
        } else {
          onSubmit({
            error: true,
            data: null,
            apiResponse: null,
            message: 'Form error - check your fields',
          });
        }
      });
    }
  }, [callback]);

  //? Método que limpia los campos al guardar el campo
  const clearFormData = () => {
    apiResponse.forEach((response: any) => {
      if (
        response.fieldTypeCode == 'textArea' ||
        response.fieldTypeCode == 'quantitySelector' ||
        response.fieldTypeCode == 'textField'
      ) {
        setValue(response.name, '');
      }
      if (
        response.fieldTypeCode == 'singleSelector' ||
        response.fieldTypeCode == 'multipleSelector'
      ) {
        setValue(response.name, []);
      }
    });
  };

  return (
    <>
      <CardUIT>
        <Grid container spacing={4} className={classes.box}>
          {apiResponse.map((response: any) => {
            return (
              <>
                <Grid
                  item
                  xs={12}
                  md={response.fieldTypeCode == 'textArea' ? 12 : 4}
                  lg={response.fieldTypeCode == 'textArea' ? 12 : 4}
                >
                  {response.fieldTypeCode == 'textArea' && (
                    <>
                      <CustomTextArea
                        name={response.name}
                        label={getFormItems(response.items, 'label')}
                        placeholder={'Ingresa'}
                        required={getValidations(response.validations, 'required')}
                        control={control}
                        maxCharacters={getValidations(response.validations, 'maxCharacters')}
                        rules={getFormValidations(response)}
                        multiline={true}
                        rows={2}
                        trigger={trigger}
                      />
                    </>
                  )}
                  {response.fieldTypeCode == 'singleSelector' && (
                    <>
                      <CustomSelect
                        data={getFormItems(response.items, 'options')}
                        name={response.name}
                        label={getFormItems(response.items, 'label')}
                        placeholder={'Selecciona'}
                        required={getValidations(response.validations, 'required')}
                        multiple={false}
                        selectAll={false}
                        isSortData={true}
                        disableCloseOnSelect={false}
                        control={control}
                        fullWidth={true}
                        rules={getFormValidations(response)}
                        disableClearable={true}
                        trigger={trigger}
                      />
                    </>
                  )}
                  {response?.fieldTypeCode == 'multipleSelector' && (
                    <>
                      <CustomSelect
                        data={getFormItems(response.items, 'options')}
                        name={response.name}
                        label={getFormItems(response.items, 'label')}
                        placeholder={'Selecciona'}
                        required={getValidations(response.validations, 'required')}
                        multiple={true}
                        limitTags={1}
                        selectAll={true}
                        isSortData={true}
                        control={control}
                        fullWidth={true}
                        rules={getFormValidations(response)}
                        trigger={trigger}
                      />
                    </>
                  )}
                  {response.fieldTypeCode == 'quantitySelector' && (
                    <>
                      <CustomTextArea
                        name={response.name}
                        label={getFormItems(response.items, 'label')}
                        placeholder={'Ingresa'}
                        required={getValidations(response.validations, 'required')}
                        control={control}
                        rules={getFormValidations(response)}
                        type="number"
                        multiline={false}
                        rows={1}
                        minValue={getValidations(response.validations, 'minValue')}
                        maxValue={getValidations(response.validations, 'maxValue')}
                        trigger={trigger}
                      />
                    </>
                  )}
                  {response.fieldTypeCode === 'textField' && (
                    <>
                      <CustomTextField
                        name={response.name}
                        label={getFormItems(response.items, 'label')}
                        placeholder={'Ingresa'}
                        required={getValidations(response.validations, 'required')}
                        control={control}
                        rules={getFormValidations(response)}
                        maxCharacters={getValidations(response.validations, 'maxCharacters')}
                        trigger={trigger}
                      />
                    </>
                  )}
                </Grid>
              </>
            );
          })}
        </Grid>
      </CardUIT>
    </>
  );
};

DynamicForm.defaultProps = {
  clearOnSubmit: false,
};

export default DynamicForm;
