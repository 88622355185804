import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { getProfesionalProviderInfo } from '../../../../../../../api/api';
import { ServiceData } from '../../../../../../../models/ServiceDataModel';
import { ServiceStepContentAuth } from '../../../ServiceStepContentAuth/ServiceStepContentAuth';
import { useStyles } from './styles';

type Props = {
  idProfessional?: number | null;
  idProvider?: number | null;
  serviceData: ServiceData | undefined;
};

export const ScheduledServiceStepContentAuth: FC<Props> = ({
  idProfessional,
  idProvider,
  serviceData,
}) => {
  const classes = useStyles();
  const [professional, setProfesional] = useState(null);
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (idProfessional != null || idProvider != null) {
      getInformation();
    }
  }, [idProfessional, idProvider]);

  const getInformation = async () => {
    const response = await getProfesionalProviderInfo(idProfessional, idProvider);
    if (response.code === 200) {
      if (response.data.professionalInfo && response.data.professionalInfo.fullName) {
        setProfesional(response.data.professionalInfo.fullName);
      }
      if (response.data.providerInfo && response.data.providerInfo.nombreEmpresa) {
        setProvider(response.data.providerInfo.nombreEmpresa);
      }
    }
  };

  return (
    <div className={classes.container}>
      <ServiceStepContentAuth serviceData={serviceData}></ServiceStepContentAuth>

      {professional && (
        <div>
          <div>
            <Typography className={classes.title}>{'Profesional:'}</Typography>
          </div>
          <div>
            <Typography className={classes.content}>{professional}</Typography>
          </div>
        </div>
      )}
      {provider && (
        <div>
          <div>
            <Typography className={classes.title}>{'Proveedor:'}</Typography>
          </div>
          <div>
            <Typography className={classes.content}>{provider}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};
