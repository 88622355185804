import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '22px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));
