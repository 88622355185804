import React, { useEffect, useState } from 'react';

import { scrollTo } from '../../../../../util/commons';
import AditionalInformation from './AditionalInformation';
import Buttons from './Buttons/index';
import ConceptTimeData from './ConceptTimeData/index';

interface DescriptionViewProps {
  handleNext: any;
  handleBack: any;
  control: any;
  setImmediateRequest: any;
  setValue: any;
  handleSubmit: any;
  watch: any;
}

const DescriptionView: React.FC<DescriptionViewProps> = ({
  handleNext,
  handleBack,
  control,
  setImmediateRequest,
  setValue,
  handleSubmit,
  watch,
}) => {
  const [errorDirection, setErrorDirection] = useState<any>(false);
  const [errorDirectionFinal, setErrorDirectionFinal] = useState<any>(false);
  const [callback, setCallback] = useState<any>(false);
  const [validData, setValidData] = useState<any>(false);

  useEffect(() => {
    scrollTo();
  }, []);

  return (
    <>
      <AditionalInformation
        control={control}
        setValue={setValue}
        watch={watch}
        errorDirection={errorDirection}
        setErrorDirection={setErrorDirection}
        errorDirectionFinal={errorDirectionFinal}
        setErrorDirectionFinal={setErrorDirectionFinal}
        callback={callback}
        setValidData={setValidData}
      />
      <ConceptTimeData
        control={control}
        setValue={setValue}
        watch={watch}
        setImmediateRequest={setImmediateRequest}
      />
      <Buttons
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        handleNext={handleNext}
        setErrorDirection={setErrorDirection}
        setErrorDirectionFinal={setErrorDirectionFinal}
        watch={watch}
        setCallback={setCallback}
        validData={validData}
      />
    </>
  );
};

export default DescriptionView;
