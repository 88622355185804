import { Check } from '@mui/icons-material';
import { StepConnector, StepIconProps, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { ReactNode, useContext } from 'react';

import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { isAuthenticate } from '../../../util/commons';
import { StepObject } from './models/StepObject';
import { useStyles } from './styles';

type StepperProps = {
  steps?: Array<StepObject>;
  activeStep: number;
} & typeof defaultProps;

const defaultProps = {
  steps: [
    { id: 1, text: 'Step 1', optional: false, visible: true },
    { id: 2, text: 'Step 2', optional: false, visible: true },
    { id: 3, text: 'Step 3', optional: false, visible: true },
  ],
};

const ServiceStepper = (props: StepperProps) => {
  const { steps, activeStep } = props;
  const classes = useStyles();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('sm'));
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);
  const isAuthenticateUser: any = isAuthenticate(businessUnitUUID);

  const isStepOptional = (optional: boolean) => {
    return optional === true;
  };

  const getClasses = (classes: any) => {
    if (isAuthenticateUser) {
      if (smallSize) return classes;
    } else {
      return classes;
    }
  };

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed } = props;

    return (
      <div className={classes.qontoStepIconRoot}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : active ? (
          <div className="QontoStepIcon-circle" />
        ) : (
          <div className="QontoStepIcon-circle-disabled" />
        )}
      </div>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        alternativeLabel={isAuthenticateUser && !smallSize ? false : !smallSize}
        activeStep={activeStep}
        connector={
          <StepConnector
            className={
              activeStep === 4
                ? getClasses(classes.qontoStepConnectorLast)
                : activeStep > 4
                ? getClasses(classes.qontoStepConnectorAllCompleted)
                : getClasses(classes.qontoStepConnector)
            }
          />
        }
        className={
          activeStep === 0
            ? getClasses(classes.stepperRootInitial)
            : activeStep === 4
            ? getClasses(classes.stepperRootLast)
            : activeStep > 4
            ? getClasses(classes.stepperRootAllCompleted)
            : getClasses(classes.stepperRoot)
        }
        sx={{
          '& .MuiStepLabel-alternativeLabel': {
            marginTop: '0px !important',
          },
        }}
      >
        {steps.map(({ text, optional, visible }, label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: ReactNode;
          } = {};
          if (isStepOptional(optional)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          return (
            visible && (
              <Step key={label} {...stepProps} className={getClasses(classes.root)}>
                <StepLabel
                  {...labelProps}
                  StepIconComponent={QontoStepIcon}
                  className={isAuthenticateUser && !smallSize ? classes.stepContent : classes.label}
                >
                  {text}
                </StepLabel>
              </Step>
            )
          );
        })}
      </Stepper>
    </Box>
  );
};

ServiceStepper.defaultProps = defaultProps;

export default ServiceStepper;
