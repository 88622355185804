import 'moment-timezone';

import moment from 'moment';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import BusinessUnitParamsContext from '../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { ServiceData } from '../../../../../models/ServiceDataModel';
import Field from '../../../../Authenticated/AssitanceRequest/Steps/RequestView/CardsTypeMaterial/Materials/Field/Field';
import { useStyles } from './styles';

type Props = {
  serviceData: ServiceData | undefined;
};

export const ServiceStepContentAuth: FC<Props> = ({ serviceData }) => {
  const { dateFormatCode, timeFormat, timezoneIdName } = useContext(BusinessUnitParamsContext);
  const timeFormatDate =
    timeFormat === '12hr' ? `hh:mma` : timeFormat === '24hr' ? `HH:mm` : dateFormatCode;
  const { t } = useTranslation();
  const classes = useStyles();
  const desiredDate = serviceData ? serviceData.desiredDate : null;

  return (
    <div className={classes.containerInfo}>
      <div>
        <Field field={t('Id Servicio: ')} value={`#${serviceData?.serviceId}`} />
      </div>
      <div>
        <Field field={t('No. Caso: ')} value={`#${serviceData?.bookingId}`} />
      </div>
      <div>
        <Field
          field={t('Fecha deseada: ')}
          value={moment(desiredDate)
            .tz(timezoneIdName || '')
            .format(dateFormatCode)}
        />
      </div>
      <Field
        field={t('Hora deseada: ')}
        value={moment(desiredDate)
          .tz(timezoneIdName || '')
          .format(timeFormatDate)}
      />
    </div>
  );
};
