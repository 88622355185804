import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  box: {
    padding: '15px 12px 20px 12px',
  },
  title: {
    color: '#000000',
    padding: '10px 12px 10px 12px',
    fontWeight: '500',
  },
}));
