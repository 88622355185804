import axios from 'axios';

export const getCountriesInformationByTenant = (
  serviceDefinitionCountriesIds: (number | undefined)[],
  tenantUUID: string | undefined
) => {
  const headers = { headers: { tenantUUID, serviceDefinitionCountriesIds } };
  return axios
    .get(`${process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL}/front-customer/countries`, headers)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
