interface CostConceptQuantity {
  costConceptId: number;
  quantity: string;
}

interface CostConceptMap {
  id: number;
  costConceptId: number;
  cost: number;
  price: number;
  quantity: number;
}

const RATE_TYPE_BASE = 4;

const getValidCostConcepts = (costConcepts: any[]): any[] => {
  return costConcepts.filter(
    (costConcept) => costConcept['validCoverage'] !== false && 'costConceptId' in costConcept
  );
};

const getQuantitiesEntered = (descriptionData: any): CostConceptQuantity[] => {
  const quantityProperties = Object.keys(descriptionData).filter((key) =>
    key.startsWith('quantity-')
  );

  return quantityProperties.map((key) => {
    const costConceptId = parseInt(key.split('-')[1], 10);
    const quantity = String(descriptionData[key]);
    return { costConceptId, quantity };
  });
};

export const mapCostConcepts = (costConcepts: any[], descriptionData: any): CostConceptMap[] => {
  const validCostConcepts: any[] = getValidCostConcepts(costConcepts);

  const quantitiesEntered: CostConceptQuantity[] = getQuantitiesEntered(descriptionData);

  return validCostConcepts
    .map((costConcept) => ({
      id: costConcept.id,
      costConceptId: costConcept.costConceptId,
      cost: costConcept.baseCost,
      price: costConcept.price,
      quantity: parseEnteredQuantity(
        costConcept.costConceptId,
        costConcept.rateType,
        quantitiesEntered
      ),
    }))
    .filter((costConcept) => costConcept.quantity > 0);
};

const isYesOrNoCostConcept = (costConceptValue: string): boolean => {
  if (!costConceptValue) {
    return false;
  }

  return costConceptValue === 'si' || costConceptValue === 'no';
};

const isYesCostConcept = (costConceptValue: string): boolean => {
  return costConceptValue === 'si';
};

const sanitizeQuantityValue = (costConceptValue: string): string => {
  if (!costConceptValue) {
    return '0.00';
  }

  if (!costConceptValue.includes('.')) {
    return costConceptValue + '.00';
  }

  if (costConceptValue.endsWith('.')) {
    return costConceptValue + '00';
  }

  const floatingPoint = costConceptValue.indexOf('.') + 1;

  const decimalPlaces = costConceptValue.length - floatingPoint;

  if (decimalPlaces < 2) {
    return costConceptValue + '0';
  }

  return costConceptValue;
};

const parseQuantityCostConcept = (costConceptValue: string): number => {
  if (!costConceptValue) {
    return 0;
  }

  const sanitizedValue = sanitizeQuantityValue(costConceptValue).replaceAll(',', '');

  try {
    const result: number = parseFloat(parseFloat(sanitizedValue).toFixed(2));

    return result;
  } catch (e) {
    return 0;
  }
};

const parseEnteredQuantity = (
  costConceptId: number,
  rateTypeId: number,
  quantitiesEntered: CostConceptQuantity[]
): number => {
  if (RATE_TYPE_BASE === rateTypeId) {
    return 1;
  }

  const costConcept: CostConceptQuantity | undefined = quantitiesEntered.find(
    (currentCostConcept) => costConceptId == currentCostConcept.costConceptId
  );

  if (!costConcept || !costConcept.quantity) {
    return 0;
  }

  const quantityAsString: string = costConcept.quantity.toLowerCase().replaceAll('$', '').trim();

  if (isYesOrNoCostConcept(quantityAsString)) {
    return isYesCostConcept(quantityAsString) ? 1 : 0;
  }

  return parseQuantityCostConcept(quantityAsString);
};
