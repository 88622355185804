import axios from 'axios';

import { getTokenAuthentication } from '../util/commons';

export const calculateGoogleDistanceBetweenSources = (
  businessUnitUUID: string | undefined,
  origin: any,
  destination: any
) => {
  const token = getTokenAuthentication(businessUnitUUID);
  const headers = { headers: { token } };
  return axios
    .get(
      `${
        process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL
      }/front-customer/service/distance?destination=${encodeURIComponent(
        destination
      )}&origin=${encodeURIComponent(origin)}`,
      headers
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
