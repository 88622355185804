import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  search: {
    borderRadius: '20px',
    backgroundColor: 'white',
    fontSize: '0.875rem',
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
}));
