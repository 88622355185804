import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getCostConcept, getCustomerInfo } from '../../../../../../api/api';
import { calculateGoogleDistanceBetweenSources } from '../../../../../../api/googleCalculateDistance';
import { SimpleAlert } from '../../../../../../components/Atoms/SimpleAlert';
import CustomPhoneInput from '../../../../../../components/Contact/CustomPhoneInput';
import InputTextController from '../../../../../../components/Contact/InputTextController';
import BusinessUnitParamsContext from '../../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setCostConcepts } from '../../../../../../redux/actions/requestActions';
import { getRequestSelector } from '../../../../../../redux/selectors';
import QuestionConcept from './components/QuestionConcept';
import { useStyles } from './styles';

interface ConceptTimeDataProps {
  control: any;
  setValue: any;
  watch: any;
  setImmediateRequest: any;
}

const ConceptTimeData: React.FC<ConceptTimeDataProps> = ({
  control,
  setValue,
  watch,
  setImmediateRequest,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkBooleanTime, setCheckBooleanTime] = useState(true);
  const { serviceId, haveServiceDestination } = useSelector(getRequestSelector);
  const [costConcept, setCostConcept] = useState([]);
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);
  const watchDirectionOrigen = watch().directionOrigen;
  const watchDirectionDestination = watch().directionDestination;

  const RATE_TYPE_UNIT = 1;
  const RATE_TYPE_EXCESS = 2;
  const RATE_DUMMY = 3;
  const RATE_BASE = 4;

  useEffect(() => {
    dateTimeNow();
    getCostConcept(serviceId.toString(), businessUnitUUID).then((res) => {
      setCostConcept(
        orderCostConcepts(
          res.map((item: any) => ({
            id: item.id,
            name: item.costConcept.name,
            measure: item.costConcept.unit.name,
            codeUnit: item.costConcept.unit.code,
            validCoverage: item.costConcept.validCoverage,
            valid: item.costConcept.question && item.costConcept.validCoverage,
            show: item.costConcept.question,
            baseCost: item.baseCost,
            excessCost: item.excessBaseSale,
            top: item.top,
            quantity: item.sale,
            amount: null,
            acceptedGenericCost: false,
            rateType: item.costConcept.rateType,
            price: item.sale,
            costConceptId: item.costConcept.id,
            unitId: item.costConcept.unit ? item.costConcept.unit.id : null,
            integrationMap:
              item.costConcept.unit.code === 'distance-Km' && item.costConcept.integrationMap,
          }))
        )
      );
    });
    getCustomerInfo(businessUnitUUID).then((res) => {
      setValue('phoneNumber', res.mobilePhone);
      setValue('email', res.email);
    });
  }, []);

  useEffect(() => {
    dispatch(setCostConcepts(costConcept));
  }, [costConcept, dispatch]);

  useEffect(() => {
    let origin = null;
    let destination = null;

    const currentCostConcept: any = costConcept.find((element: any) => element.integrationMap);

    if (currentCostConcept) {
      if (watchDirectionOrigen && watchDirectionOrigen.position) {
        origin = watchDirectionOrigen.position.lat + ',' + watchDirectionOrigen.position.lng;
      }

      if (watchDirectionDestination && watchDirectionDestination.position) {
        destination =
          watchDirectionDestination.position.lat + ',' + watchDirectionDestination.position.lng;
      }

      if (destination) {
        calculateGoogleDistanceBetweenSources(businessUnitUUID, origin, destination).then((res) => {
          const distanceInMeters = res.rows[0].elements[0]?.distance?.inMeters;
          if (distanceInMeters) {
            const distance = distanceInMeters / 1000;
            setValue(`quantity-${currentCostConcept?.costConceptId}`, distance);
          } else {
            setValue(`quantity-${currentCostConcept?.costConceptId}`, 0);
          }
        });
      } else {
        setValue(`quantity-${currentCostConcept?.costConceptId}`, 0);
      }
    }
  }, [watchDirectionOrigen, watchDirectionDestination]);

  const orderCostConcepts = (costConcepts: any) => {
    const costConceptOrder = costConcepts.sort((a: any, b: any) => {
      if (a.integrationMap) {
        return -1;
      }
      if (b.integrationMap) {
        return 1;
      }
      if (a.rateType < b.rateType) {
        return -1;
      }
      if (a.rateType > b.rateType) {
        return 1;
      }
      return 0;
    });
    return costConceptOrder;
  };

  const dateTimeNow = () => {
    const now = moment().format('YYYY-MM-DD - HH:mm');
    const currentDate = now.split(' - ')[0];
    const currentTime = now.split(' - ')[1];
    setValue('date', currentDate, { shouldValidate: true });
    setValue('time', currentTime, { shouldValidate: true });
  };

  const handleCheckboxChange = (event: any) => {
    if (event.target.checked) {
      dateTimeNow();
      setImmediateRequest(true);
      setCheckBooleanTime(true);
    } else {
      setImmediateRequest(false);
      setCheckBooleanTime(false);
    }
  };

  const checkCostConcepts = () => {
    const costConcepts = costConcept.filter((item: any) => item.show);
    if (costConcepts.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const getShowDefaultDataWithIntegrationMap = (item: any) => {
    if (haveServiceDestination) {
      return item.integrationMap;
    } else {
      return false;
    }
  };

  const getQuantity = (item: any) => {
    if (item.rateType === RATE_TYPE_UNIT) {
      if (item.integrationMap) {
        return haveServiceDestination ? '' : 0;
      } else {
        return '';
      }
    } else {
      return item.rateType === RATE_TYPE_EXCESS ? '' : item.quantity;
    }
  };

  return (
    <>
      {costConcept.length > 0 && checkCostConcepts() && (
        <>
          <Typography variant={'body1'} className={classes.title} sx={{ marginTop: '24px' }}>
            Completa los detalles de la solicitud
          </Typography>
          {costConcept.map((item: any, index: number) => {
            return item.show && item.rateType !== RATE_BASE ? (
              <QuestionConcept
                key={index}
                name={item.name}
                measure={item.measure}
                quantity={getQuantity(item)}
                control={control}
                showDefault={
                  item.rateType === RATE_BASE ||
                  (item.rateType === RATE_TYPE_UNIT && getShowDefaultDataWithIntegrationMap(item))
                }
                showRadio={item.rateType === RATE_DUMMY}
                id={item.costConceptId}
              />
            ) : (
              <></>
            );
          })}
        </>
      )}
      <Typography variant={'body1'} className={classes.title} sx={{ marginTop: '24px' }}>
        Elige fecha y hora deseada
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={5}>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                color="primary"
                checked={checkBooleanTime}
                onChange={(e) => handleCheckboxChange(e)}
              />
            }
            label=""
            labelPlacement="end"
            className={checkBooleanTime ? classes.checkboxField : classes.checkboxDisabledTime}
          />
          <Typography variant={'body2'} className={classes.text}>
            Utilizar fecha y hora actual.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="date"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                error={!!error}
                value={value}
                disabled={checkBooleanTime}
                onChange={(e) => {
                  onChange(e);
                  setCheckBooleanTime(false);
                  setImmediateRequest(false);
                }}
                label={'Fecha de la asistencia'}
                type="date"
                variant="outlined"
                className={classes.dateField}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    asterisk: classes.asterikColor,
                    focused: classes.cssFocused,
                    root: error ? classes.errorColor : classes.labelRoot,
                  },
                }}
                InputProps={{
                  inputProps: {
                    min: value,
                  },
                  classes: {
                    root: classNames(error ? classes.errorColor : classes.root),
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                required
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Fecha de la asistencia requerida',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="time"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                error={!!error}
                value={value}
                disabled={checkBooleanTime}
                onChange={(e) => {
                  onChange(e);
                  setCheckBooleanTime(false);
                  setImmediateRequest(false);
                }}
                label={'Hora de la asistencia'}
                type="time"
                variant="outlined"
                className={classes.dateField}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    asterisk: classes.asterikColor,
                    focused: classes.cssFocused,
                    root: error ? classes.errorColor : classes.labelRoot,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classNames(error ? classes.errorColor : classes.root),
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                required
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Hora de la asistencia requerida',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'body2'} className={classes.text}>
            Ejemplo de formato: DD/MM/AAAA
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <SimpleAlert
            type={'info'}
            text={
              'Esta información es meramente informativa y no necesariamente es la fecha y hora final del servicio agendado.'
            }
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography variant={'body1'} className={classes.title} sx={{ marginTop: '24px' }}>
        Datos de contacto
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={5} sx={{ marginTop: '5px' }}>
        <Grid item xs={12} sm={4}>
          <CustomPhoneInput
            id={'phoneNumber'}
            name={'phoneNumber'}
            control={control}
            label={'Teléfono'}
            required
            variant={'outlined'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputTextController
            name={'email'}
            label={'Correo electrónico'}
            placeholder={'contacto@correo.com'}
            control={control}
            type={'email'}
            minLength={1}
            required={true}
            className={classes.formInput}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ConceptTimeData;
