import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  alert: {
    width: 'fit-content',
    marginTop: '16px',
  },
  fullWidth: {
    width: '100%',
    marginTop: '16px',
    alignItems: 'center',
    paddingLeft: '20px',
  },
}));
