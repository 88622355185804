import { FC, useContext } from 'react';

import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { useStyles } from './styles';

const ContainerPage: FC = ({ children }) => {
  const classes = useStyles();
  const { customerPortalCoverURL } = useContext(BusinessUnitParamsContext);

  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>{children}</div>
      <div
        className={classes.rightColumn}
        style={{ backgroundImage: `url(${customerPortalCoverURL})` }}
      />
    </div>
  );
};

export default ContainerPage;
