type CallbackFunction = (response: any) => void;

type RuleMapModel = {
  required: CallbackFunction;
  maxCharacters: CallbackFunction;
  min: CallbackFunction;
  max: CallbackFunction;
};

type ResponseModel = {
  code: string;
};

export const getFormItems = (item: any, field: any) => {
  const codeMap = {
    label: 'fieldName',
    placeholder: 'indicativeText',
    options: 'selectionOptions',
  };
  const code = codeMap[field as keyof typeof codeMap];
  if (code) {
    const label = item.find((t: any) => t.code == code);
    if (field == 'options') {
      const array = JSON.parse(label.value);
      const arrayResult = array.map((option: any) => ({
        label: option.name,
        value: option.name,
        optionValue: option.optionType,
      }));
      return arrayResult;
    } else {
      return label.value;
    }
  }
};

export const getFormValidations = (item: any) => {
  const rules: any = {};
  const ruleMap: RuleMapModel = {
    required: (response: any) => {
      const value = response.value == 'true';
      rules.required = { value: value, message: response.message };
    },
    maxCharacters: (response: any) => {
      const number = Number(response.value);
      rules.maxLength = { value: number, message: response.message };
    },
    min: (response: any) => {
      const number = Number(response.value);
      rules.min = { value: number, message: response.message };
    },
    max: (response: any) => {
      const number = Number(response.value);
      rules.max = { value: number, message: response.message };
    },
  };
  item.validations.forEach((response: ResponseModel) => {
    const ruleFunc = ruleMap[response.code as keyof RuleMapModel];
    if (ruleFunc) {
      ruleFunc(response);
    }
  });

  return rules;
};

export const getValidations = (item: any, field: any) => {
  const codeMap = {
    required: 'required',
    maxCharacters: 'maxCharacters',
    minValue: 'min',
    maxValue: 'max',
  };
  const code = codeMap[field as keyof typeof codeMap];
  if (code) {
    const label = item.find((t: any) => t.code == code);
    if (field == 'required') {
      const value = label.value == 'true';
      return value;
    } else if (field == 'minValue' || field == 'maxValue') {
      const number = Number(label.value);
      return number;
    } else {
      return label.value;
    }
  }
};
