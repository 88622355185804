import CircularProgress from '@mui/material/CircularProgress';

import { useStyles } from './styles';

function CircularProgressCustom() {
  const classes = useStyles();

  return (
    <div className={classes.containerProgress}>
      <CircularProgress className={classes.progress} />
    </div>
  );
}

export default CircularProgressCustom;
